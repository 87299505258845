import jQuery from 'jquery';

jQuery(($) => {
  $('#entityTypeSelect').on('change', (event) => {
    const selectedEntity = event.target.value;
    if (selectedEntity === 'individual') {
      $('#individual_form').show();
      $('#authorized_fund_form').hide();
      $('#authorized_entity_form').hide();
    } else if (selectedEntity === 'venture_fund') {
      $('#individual_form').hide();
      $('#authorized_fund_form').show();
      $('#authorized_entity_form').hide();
    } else if (selectedEntity === 'entity') {
      $('#individual_form').hide();
      $('#authorized_fund_form').hide();
      $('#authorized_entity_form').show();
    }
  });
});
